import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "self" ]
  static values = { madeUrl: String }

  initialize() {
    this.beep = new Audio('https://portal.broadnet.systems/NOTIFI.mp3')
  }

  connect() {
    console.log(`Callbacke#connect: fetch: ${this.madeUrlValue}`)

    this.beep.play()
  }

  callMade(event) {
    let that = this

    Rails.ajax({
      type: 'GET',
      url: this.madeUrlValue,
      dataType: 'json',
      success: function (response) {
        that.selfTarget.hidden = true
      },
      error: function (response) {
        console.log('filter failed')
      }
    })
  }

}
