import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ["query", "results"]

    connect() {
        console.log('Search: connected')
    }

    get_results() {
        console.log('Key pressed. Sending: ' + this.data.get('url') + '?looking_for=' + this.queryTarget.value)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: this.data.get('url') + '?looking_for=' + this.queryTarget.value,
            dataType: 'json',

            success: function (response) {

                console.log(response.html)

                that.resultsTarget.innerHTML = response.html

            },
            error: function (response) { console.log('Search: Failed.')}
        })
    }

}