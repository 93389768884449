import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "editing", "content" ]

  connect() {
    console.log('Connected: Manage Callsigns')
  }

  editCallsign(event) {
    console.log('ManageCallsigns#editCallsign')

    let that = this
    let url = event.target.closest('button').name

    console.log(`URL: ${url}`)

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function (response) {
        console.log('New Callsign Edit box')
        that.editingTarget.innerHTML = response.html
        that.contentTarget.innerHTML = response.list
      },
      error: function (response) {
        console.log('filter failed')
      }
    })


  }
}
