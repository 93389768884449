// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "callbacks", "closed", "search", "list", "results", "map", "incident", "messages", 'messagesTab', "notes",
    "incidentContent", "terminals", 'content', 'submit', 'allocations', 'chat', 'terminalDetails',
    'openTerminalsBtn', 'openMapBtn' ,'vrm', 'times']
  static values = { searching: Boolean, vrmUrl: String }

  initialize() {
  }

  connect() {
    // console.log('Connected: Incidents')
    this._new_path = this.data.get('new-url')
    this._map_closed = false
  }

  showChat(event) {
    let that = this

    this.chatTarget.hidden = false
    this.mapTarget.hidden = true

    // Fetch the messages

    Rails.ajax({
      type: 'GET',
      url: this.data.get('messages-url'),
      dataType: 'json',
      success: function (response) {
        that.contentTarget.innerHTML = response.html
      },
      error: function (response) {
        console.log('filter failed')
      }
    })

  }

  openList()  {
    this.listTarget.hidden = false
    this.openlistBtnTarget.hidden = true

  }

  closeList()  {
    this.listTarget.hidden = true
    this.openlistBtnTarget.hidden = false

  }

  openMap()  {
    this._map_closed = false
    this.mapTarget.hidden = false
    this.openMapBtnTarget.hidden = true
  }

  closeMap()  {
    this._map_closed = true
    this.mapTarget.hidden = true
    this.openMapBtnTarget.hidden = false

  }

  openVrm() {
    this.vrmTarget.hidden = false
  }

  closeVrm() {
    this.vrmTarget.hidden = true

  }

  openMessages() {

    // this.listTarget.hidden     = true
    this.messagesTarget.hidden = false
    this.messagesTabTarget.innerHTML = '<i class="fal fa-comments-alt"></i> Messages'

  }

  closeMessages() {
    // this.listTarget.hidden     = false
    this.messagesTarget.hidden = true
    this.messagesTabTarget.innerHTML = '<i class="fal fa-comments-alt"></i> Messages'

  }

  openTerminals()  {
    this.terminalsTarget.hidden = false
    this.openTerminalsBtnTarget.hidden = true

  }

  closeTerminals()  {
    this.terminalsTarget.hidden = true
    this.openTerminalsBtnTarget.hidden = false
  }

  openNotes() {
    // this.listTarget.hidden     = true
    this.notesTarget.hidden = false

  }

  closeNotes() {
    // this.listTarget.hidden     = false
    this.notesTarget.hidden = true
  }

  openCallbacks() {
    // this.listTarget.hidden     = true
    this.callbacksTarget.hidden = false

  }

  closeCallbacks() {
    // this.listTarget.hidden     = false
    this.callbacksTarget.hidden = true
  }

  openSearch() {
    let that = this

    this.listTarget.hidden = true
    this.searchTarget.hidden = false
    this.resultsTarget.hidden = false
    this.incidentTarget.hidden = true
    this.mapTarget.hidden = true
    this.searchingValue = true
  }

  backToMap() {
    this.listTarget.hidden     = false
    this.searchTarget.hidden   = true
    this.resultsTarget.hidden  = true
    this.incidentTarget.hidden = true
    this.mapTarget.hidden      = false
    this.searchingValue        = false
  }

  newIncident() {
    let that = this

    this.incidentTarget.hidden = false
    this.mapTarget.hidden = false
    this.listTarget.hidden = true

    Rails.ajax({
      type: 'GET',
      url: this._new_path,
      dataType: 'json',
      success: function (response) {
        that.incidentTarget.innerHTML = response.html
      },
      error: function (response) { console.log('Terminals could not be fetched.')}
    })
  }

  abandonIncident() {
    this.incidentTarget.hidden  = true
    this.terminalsTarget.hidden = false
    this.listTarget.hidden      = false
  }

  createIncident() {
    this.incidentTarget.hidden  = true
    this.listTarget.hidden      = false
  }

  submitForm(event) {
    let that = this

    console.log('Submit Form so Prevent')
    event.preventDefault()

    let data = new FormData(this.submitTarget)
    data.append("commit", event.target.value)

    Rails.ajax({
      type: "post",
      url: this.data.get('create-url'),
      data: data,
      success: function (response) {
        console.log("Incident :" + response.html)
        that.incidentTarget.innerHTML = response.html
      },
      error: function (response) { console.log('Submit: Failed.')}
    })

    // Redraw the table
  }

  openIncident(event) {

    let that = this

    let url = event.target.name
    if (url == undefined) {
      url = event.target.closest('div').getAttribute('name');
    }

    console.log(event.target.name)

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function (response) {
        if (response.html == undefined) {
        } else {
          that.mapTarget.hidden = true
          that.resultsTarget.hidden = true
          that.incidentTarget.hidden = false
          that.incidentTarget.innerHTML = response.html
        }

      },
      error: function (response) { console.log('Terminals could not be fetched.')}
    })

  }

  showTimes() {
    this.timesTarget.hidden = false
  }

  hideTimes() {
    this.timesTarget.hidden = true
  }

  hideIncident(event) {
    if (this.searchingValue) {
      this.resultsTarget.hidden  = false
      this.incidentTarget.hidden = true
    } else {
      this.mapTarget.hidden        = this._map_closed
      this.incidentTarget.hidden   = true
      this.listTarget.hidden       = false
    }

  }

  dragStartTerminal(event) {
    this._terminals_ident = event.target.getAttribute('data-terminal-id')

    event.dataTransfer.setData("application/drag-key", event.target.getAttribute("data-terminal-id"))
    event.dataTransfer.effectAllowed = "move"
  }

  dragover(event) {
    event.preventDefault()
    return true
  }

  dragenter(event) {
    event.preventDefault()
  }

  dragDropTerminal(event) {
    let that = this

    let terminal_id = event.dataTransfer.getData("application/drag-key")
    let incident_url = this.allocationsTarget.getAttribute('data-incident-url')

    let url = incident_url + '?handset_user_id=' + terminal_id;
    console.log('Drop: ' + terminal_id + ' onto ' + url)

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function (response) {
        // that.allocationsTarget.outerHTML = response.html
      },
      error: function (response) {
        console.log('filter failed')
      }
    })


    event.preventDefault()
  }

  dragEndTerminal(event) {
  }

  showTerminalDetails() {
    this.terminalDetailsTarget.hidden = false
    this.mapTarget.hidden             = true
    this.listTarget.hidden            = true

  }

  closeTerminalDetails() {
    this.terminalDetailsTarget.hidden = true
    this.mapTarget.hidden             = false
    this.listTarget.hidden            = false

  }

}
