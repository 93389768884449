// ONLY EDIT IN STIMULUS-BASE 1.2.0

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["field", "value", "state", "switchOn", "switchOff"]

    connect() {
        console.log('Filters: Connected')

        this.filter_url = this.data.get('url')
    }

    clear(event) {
        console.log('supertable-filter#clear')
        this.valueTarget.value = ""
    }

    filterChanged() {
        let that = this
        console.log('key pressed')
        console.log('Path: ' + this.url + '?field=' + this.data.get("field") +'&by=' + this.valueTarget.value)

        Rails.ajax({
            type: 'GET',
            url: this.url + '?field=' + this.data.get("field") +'&by=' + this.valueTarget.value,
            dataType: 'json',
            success: function (response) {
                that.valueTarget.classList.add('border-info')
                that.stateTarget.classList.add('border-info')
                $('#table-body').replaceWith(response.html)
            },
            error: function (response) {
                console.log('filter failed')
            }
        })

    }

    // These things below can go in supertable itself.

    toggleOn(event) {
        this.switchOffTarget.hidden = true
        this.switchOnTarget.hidden = false
    }

    toggleOff(event) {
        this.switchOffTarget.hidden = false
        this.switchOnTarget.hidden  = true
    }

}