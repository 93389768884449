import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('Toast#connect')


    this.toast = new bootstrap.Toast(this.element, {
      keyboard: false
    })
    this.toast.show()
  }
}