import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="org-logs"
export default class extends Controller {
  connect() {

  }

  openIncident(event) {

    let that = this

    let url = event.target.name
    if (url == undefined) {
      url = event.target.closest('div').getAttribute('name');
    }

    console.log(event.target.name)

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function (response) {
        console.log('Lets display the Incident: ' + response.html)

        if (response.html == undefined) {
          console.log('could not display incident')
        } else {
          that.mapTarget.hidden = true
          that.resultsTarget.hidden = true
          that.incidentTarget.hidden = false
          that.incidentTarget.innerHTML = response.html
        }

      },
      error: function (response) { console.log('Terminals could not be fetched.')}
    })

  }


}
