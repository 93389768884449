import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets =
        ["ptt", "csn",
            "pttNumCard", "csnNumCard",
            "startCardPtt", "startCardCsn",
            "clickToStartCardPtt", "clickToStartCardCsn",
            "pttCardUndo", "csnCardUndo",
            "directDebitDescription", "cardDescription"]
    static values =
        {
            ptt: Number, csn: Number,
            pttNumCard: Number, csnNumCard: Number,
            description: String, cardDescription: String,
            update: String, cardUpdate: String,
            createDdPath: String,
            startPttPath: String, startCsnPath: String
        }

    connect() {
        console.log('Subscriptions#connect')

        this._starting_ptt = this.pttValue
        this._starting_csn = this.csnValue
        this._starting_ptt_card = this.pttNumCardValue
        this._starting_csn_card = this.csnNumCardValue

        //this.startCardPttTarget.hidden = true
       // this.startCardCsnTarget.hidden = true


    }

    startPttCard(event) {
        this.pttNumCardTarget.disabled = false
        this.pttCardUndoTarget.hidden = false
      //  this.startCardPttTarget.hidden = false
        this.clickToStartCardPttTarget.hidden = true
    }

    startCsnCard(event) {
        this.csnNumCardTarget.disabled = false
        this.csnCardUndoTarget.hidden = false
        //this.startCardCsnTarget.hidden = false
        this.clickToStartCardCsnTarget.hidden = true
    }

    upPttCard(event) {
        console.log('Subscriptions#upPttCard')
        this.pttNumCardValue += 1
        this.pttNumCardTarget.value = this.pttNumCardValue

        this.dd_description(this.pttNumCardValue, this.csnNumCardValue)
    }

    revertPttCard(event) {
        console.log('Subscriptions#revertPttCard')
        this.pttNumCardValue = this._starting_ptt_card
        this.pttNumCardTarget.value = this._starting_ptt_card

        this.cardUpdatePtt()

    }

    upPtt(event) {
        console.log('Subscriptions#upPtt')
        this.pttValue += 1
        this.pttTarget.value = this.pttValue

        this.dd_description(this.pttValue, this.csnValue)
    }

    revertPtt(event) {
        console.log('Subscriptions#revertPtt')
        this.pttValue = this._starting_ptt
        this.pttTarget.value = this._starting_ptt

        this.dd_description(this.pttValue, this.csnValue)
    }


    checkTotalPtt(event) {
        console.log('Subscriptions#checkTotalPtt')

        this.pttValue = this.pttTarget.value

        if (this.pttTarget.value < 0) {
            this.pttTarget.value = 0
            this.pttValue = 0
        }

        this.pttTarget.value = Math.round(this.pttTarget.value)

        this.dd_description(this.pttValue, this.csnValue)
    }

    upCsnCard(event) {
        console.log('Subscriptions#upCsnCard')
        this.csnNumCardValue += 1
        this.csnNumCardTarget.value = this.csnNumCardValue

        this.dd_description(this.pttNumCardValue, this.csnNumCardValue)
    }

    revertCsnCard(event) {
        console.log('Subscriptions#revertCsnCard')
        this.csnNumCardValue = this._starting_csn_card
        this.csnNumCardTarget.value = this._starting_csn_card

        this.cardUpdateCsn()
    }

    upCsn(event) {
        console.log('Subscriptions#upCsn')
        this.csnValue += 1
        this.csnTarget.value = this.csnValue

        this.dd_description(this.pttValue, this.csnValue)
    }

    revertCsn(event) {
        console.log('Subscriptions#revertCsn')
        this.csnValue = this._starting_csn
        this.csnTarget.value = this._starting_csn

        this.dd_description(this.pttValue, this.csnValue)
    }

    checkTotalCsn(event) {
        console.log('Subscriptions#checkTotalCsn')

        this.csnValue = this.csnTarget.value

        if (this.csnTarget.value < 0) {
            this.csnTarget.value = 0
            this.csnValue = 0
        }

        this.csnTarget.value = Math.round(this.csnTarget.value)

        this.dd_description(this.pttValue, this.csnValue)
    }

    dd_description(ptt, csn) {
        let that = this
        Rails.ajax({
            type: 'GET',
            url: this.descriptionValue + `?ptt=${ptt}&csn=${csn}`,
            dataType: 'json',
            success: function (response) {
                that.directDebitDescriptionTarget.innerHTML = response.html
            },
            error: function (response) {
                console.log('filter failed')
            }
        })
    }

    ddSetUp(event) {
        console.log(`Subscriptions#ddSetUp createDdPath = ${this.createDdPathValue}`)

        let that = this

        // First Update the values stored for the direct debit
        this.ddUpdate(event)

        setTimeout(function () {
            // start the dd process
            Rails.ajax({
                type: 'GET',
                url: that.createDdPathValue,
                dataType: 'json',
                success: function (response) {
                },
                error: function (response) {
                    console.log('filter failed')
                }
            })
        }, 1000);
    }

    ddUpdate(event) {
        let that = this
        Rails.ajax({
            type: 'GET',
            url: this.updateValue + `?ptt=${this.pttValue}&csn=${this.csnValue}`,
            dataType: 'json',
            success: function (response) {
                console.log(response.html)
                that.directDebitDescriptionTarget.innerHTML = response.html
                that.directDebitDescriptionTarget.classList.remove('alert-info')
                that.directDebitDescriptionTarget.classList.add('alert-success')
            },
            error: function (response) {
                console.log('filter failed')
            }
        })
    }

    cardUpdatePtt(event) {
        console.log(`Subscriptions#cardUpdatePtt path= ${this.startPttPathValue}`)
        let that = this

        if (this.pttNumCardTarget.value < 0) {
            this.pttNumCardTarget.value = 0
            this.pttNumCardValue = 0
        }

        fetch(this.startPttPathValue + `?ptt=${this.pttNumCardTarget.value}`, {
            method: that.method,
            headers: {
                Accept: "text/vnd.turbo-stream.html",
                "X-CSRF-Token": that.csrfToken,
            }
        })
            .then(r => r.text())
            .then(html => Turbo.renderStreamMessage(html))
    }

    cardUpdateCsn(event) {
        console.log(`Subscriptions#cardUpdateCsn path= ${this.startCsnPathValue}`)
        let that = this

        if (this.csnNumCardTarget.value < 0) {
            this.csnNumCardTarget.value = 0
            this.csnNumCardValue = 0
        }

        Rails.ajax({
            type: 'GET',
            url: this.startCsnPathValue + `?csn=${this.csnNumCardTarget.value}`,
            dataType: 'json',
            success: function (response) {
                that.cardDescriptionTarget.innerHTML = response.html
                document.getElementById('csn-payment-buttons').outerHTML = response.buttons
            },
            error: function (response) {
                console.log('filter failed')
            }
        })
    }


}
