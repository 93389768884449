// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";


export default class extends Controller {
  static targets = [ "buttons", "options", "action", "follow", "submit", "status" ]

  initialize() {
    console.log('Connected: Incident Actions')
  }

  performAction(event) {
    let that = this

    console.log('Get all options')
    console.log(event.target.value)

    Rails.ajax({
      type: 'GET',
      url: event.target.value,
      dataType: 'json',

      success: function (response) {
        console.log('Get: Worked')
        console.log(response.html)
        console.log(response.operator)

        that.optionsTarget.hidden    = true
        that.buttonsTarget.innerHTML = response.html

        // that.closeModal(that.actionTarget)
        that.followTarget.innerHTML = response.needed
      },
      error: function (response) { console.log('Search: Failed.')}
    })

  }

  getAll() {
    let that = this

    console.log('Get all options')

    Rails.ajax({
      type: 'GET',
      url: this.data.get('actions-url'),
      dataType: 'json',

      success: function (response) {
        console.log('Get: Worked')
        console.log(response.html)

        that.optionsTarget.hidden    = false
        that.optionsTarget.innerHTML = response.html
        // that.actionTarget.classList.add('modal-open')
        // that.actionTarget.setAttribute("style", "display: block;")
        // that.actionTarget.classList.add("show")
        // document.body.innerHTML += '<div class="modal-backdrop fade show"></div>'
      },
      error: function (response) { console.log('Search: Failed.')}
    })

  }

  open(event) {
    let that = this

    console.log('Get all options')
    console.log(event.target.value)

    Rails.ajax({
      type: 'GET',
      url: event.target.value,
      dataType: 'json',

      success: function (response) {
        console.log('Get: Re-open Ticket')
        console.log(response.html)

        that.buttonsTarget.innerHTML = response.html
        that.optionsTarget.innerHTML = ''
      },
      error: function (response) { console.log('Search: Failed.')}
    })
  }

  close(event) {
    let that = this

    let url = event.target.closest('button').value

    console.log('Get all options')
    console.log(url)

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',

      success: function (response) {
        console.log('Get: Re-open Ticket')
        console.log(response.html)

        that.buttonsTarget.innerHTML = response.html
        that.optionsTarget.innerHTML = ''
      },
      error: function (response) { console.log('Search: Failed.')}
    })
  }



  closeModal(target) {
    target.classList.remove("modal-open")
    target.removeAttribute("style")
    target.classList.remove("show")
    document.getElementsByClassName("modal-backdrop")[0].remove()
  }

  preventSubmit(event) {
    console.log('Prevent Submit')
    event.preventDefault()
  }

  submitForm(event) {
    let that = this

    console.log('Submit Form so Prevent')
    event.preventDefault()
    // Rails.fire(this.submitTarget, 'submit')


    Rails.ajax({
      type: "post",
      url: event.target.closest('form').getAttribute('action'),
      dataType: 'json',
      data: new FormData(this.submitTarget),
      success: function (response) {
        console.log('Submit: Incident Action')

        that.followTarget.innerHTML = ''
        that.statusTarget.outerHTML = response.incident_status




      },
      error: function (response) { console.log('Submit: Failed.')}
    })

    // Redraw the table

  }

}
