import Rails from "@rails/ujs" // Want to remove this in the end
import {Application} from "@hotwired/stimulus"
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import CharacterCounter from 'stimulus-character-counter'
import * as ActiveStorage from "@rails/activestorage"
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle"
import jquery from 'jquery';
import "@hotwired/turbo-rails"

const application = Application.start()
Rails.start()
ActiveStorage.start()

application.register('character-counter', CharacterCounter)
application.register('textarea-autogrow', TextareaAutogrow)

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application

$ = window.$ = window.jquery = jquery;
window.bootstrap = bootstrap

// require("trix")
// require("@rails/actiontext")

window.dispatchMapsEvent = function (...args) {
    console.log('google loaded')

    const event = document.createEvent("Events")
    event.initEvent("google-maps-callback", true, true)
    event.args = args
    window.dispatchEvent(event)
}

document.addEventListener('turbo:load', function () {
    var div = document.getElementById('payment')
    if (div === null) {
        return
    }

    stripe_public_key = document.querySelector('meta[name="stripe-public-key"]')
        .getAttribute('content')
    stripe = Stripe(stripe_public_key)
    stripe.redirectToCheckout({
        sessionId: div.getAttribute('data-session-id')
    }).then(function (results) {

    })
})

document.addEventListener('turbo:load', function () {
    var div = document.getElementById('payment_intent')
    if (div === null) {
        return
    }

    stripe_public_key = document.querySelector('meta[name="stripe-public-key"]')
        .getAttribute('content')
    stripe = Stripe(stripe_public_key)

    stripe
        .confirmCardPayment(div.getAttribute('data-client-secret'), {
            payment_method: div.getAttribute('data-payment-method-id'),
        })
        .then(function (results) {

        })
})


import "./base_script/application.js" // Get the legacy script in
import "./controllers"
import "./sweetalert"

console.log('application.js')

