// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import swal from "sweetalert2";

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    console.log("Button#connect")
  }

  disableIt(event) {
    let width = event.target.offsetWidth
    event.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
    event.target.style.width = `${width}px`
    event.target.removeAttribute('data-action')
  }

  delete(event) {
    let that = this

    console.log('Delete')

    const swalWithBootstrap = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false
    })

    console.log('alert starting ' + that.confirmValue)

    swalWithBootstrap.fire({
      html: event.params.message,
      showCancelButton: true,
      confirmButtonText: 'Okay',
      cancelButtonText: 'Cancel'
    })
        .then((result) => {
          if (result.value) {
            Rails.ajax({
              type: 'DELETE',
              url: event.params.path,
              dataType: 'json',
              success: function (response) {
              },
              error: function (response) { }
            })

          }
        })
    console.log('alert ended')
  }
}
