import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["callbacks", "callbacksTab", "toast", "terminalDetails", "allMessages", "messagesTab"]
    static values = { organisation: Number }

    connect() {
        this._org_id = this.data.get('id')
    }

    initialize() {
        this.subscription()
        this.subscriptionCallbacks()
    }

    terminalDetails(event) {
        console.log('terminals#terminalDetails')

        this.url = event.target.dataset.terminalsUrl

        console.log('Show Terminal Details ' + this.url)
        console.log('Org ID:' + this.organisationValue)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: that.url + '?organisation_id=' + that.organisationValue,
            dataType: 'json',

            success: function (response) {
                that.terminalDetailsTarget.innerHTML = response.html
            },
            error: function (response) { console.log('Search: Failed.')}
        })
    }

    subscription() {
        console.log(`Terminals#subscription ${this.organisationValue}`)
        if (this._subscription === undefined) {
            let that = this

            this._subscription = consumer.subscriptions.create({channel: "ChatChannel",
                id: that.organisationValue}, {
                connected() {
                    console.log(`Terminals#subscription - connected ${that.organisationValue}`)
                },
                disconnected() {
                    console.log('Terminals#disconnected')
                },
                received(data) {
                    console.log('Terminals#subscription - received')
                    that.allMessagesTarget.innerHTML = data.message + that.allMessagesTarget.innerHTML
                    that.messagesTabTarget.innerHTML = '<i class="fad fa-comments-alt" style="--fa-secondary-color: orangered;--fa-secondary-opacity: 1.0;"></i> Messages'
                },
            })
        }

        return this._subscription
    }

    subscriptionCallbacks() {
        console.log(`Terminals#subscriptionCallbacks ${this.organisationValue}`)
        if (this._subscriptionCallback === undefined) {
            let that = this

            this._subscriptionCallback = consumer.subscriptions.create({channel: "CallbackChannel",
                id: that.organisationValue}, {
                connected() {
                    console.log(`Terminals#subscriptionCallbacks - connected ${that.organisationValue}`)
                },
                disconnected() {
                    console.log('Terminals#disconnectedCallbacks - disconnected')
                },
                received(data) {

                    let callback = document.getElementById(`handset-callback-${data.id}`);

                    if (callback) {
                        callback.remove()
                    }

                    console.log('Terminals#subscriptionCallback - received')
                    that.callbacksTarget.innerHTML = data.callback + that.callbacksTarget.innerHTML
                    that.callbacksTabTarget.innerHTML = '<i class="fad fa-child" style="--fa-secondary-color: orangered;--fa-secondary-opacity: 1.0;"></i> Callbacks'
                },
            })
        }

        return this._subscription
    }

}