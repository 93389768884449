import { Controller } from "@hotwired/stimulus"
// import consumer from "channels/consumer"
import Rails from "@rails/ujs";

// This controller is used on the temrinal details panel.

export default class extends Controller {
    static targets = ["tracking", "messages"]
    static values = { startTrackingUrl: String, ident: String }

    connect() {
        this._org_id = this.data.get('id')
        this.subscription()
    }


    initialize() {
        // this.subscription()
    }

    subscription() {
        console.log(`Terminal#subscription ${this.identValue}`)
        if (this._subscription === undefined) {
            let that = this

            this._subscription = consumer.subscriptions.create({channel: "TerminalMessagesChannel",
                id: that.identValue}, {
                connected() {
                    console.log(`Terminal#subscription - connected ${that.identValue}`)
                },
                disconnected() {
                    console.log('TerminalMessages#disconnected')
                },
                received(data) {
                    console.log('Terminal::TerminalMessages#received')

                    that.messagesTarget.innerHTML = data.message + that.messagesTarget.innerHTML
                },
            })
        }

        return this._subscription
    }

    startTracking(event) {
        let that = this

        console.log('Start: ' + this.startTrackingUrlValue)

        Rails.ajax({
            type: 'GET',
            url: this.startTrackingUrlValue,
            dataType: 'json',

            success: function (response) {
                that.trackingTarget.outerHTML = response.html
                document.getElementById('map-buttons').outerHTML = response.buttons
            },
            error: function (response) { console.log('Search: Failed.')}
        })

    }

    stopTracking(event) {
        let that = this

        console.log('Stop: ' + event.target.name)

        Rails.ajax({
            type: 'GET',
            url: event.target.name,
            dataType: 'json',

            success: function (response) {
                that.trackingTarget.outerHTML = response.html
                document.getElementById('map-buttons').outerHTML = response.buttons
            },
            error: function (response) { console.log('Search: Failed.')}
        })
    }


    ///////////////////////////

    terminal_details(event) {

        this.url = event.target.dataset.terminalsUrl

        console.log('Show Terminal Details ' + this.url)
        console.log('Org ID:' + this._org_id)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: that.url + '?organisation_id=' + that._org_id,
            dataType: 'json',

            success: function (response) { console.log('Terminal: Got details') },
            error: function (response) { console.log('Search: Failed.')}
        })
    }


}