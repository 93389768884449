// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["self", "body", "submit"]

    connect() {
        console.log(`IncidentLocation#connect`)
    }

    preventSubmit(event) {
        console.log('Prevent Submit')
        event.preventDefault()
    }

    edit(event) {
        console.log(`IncidentLocation#edit path='${event.params.path}'`)

        let that = this
        Rails.ajax({
            type: 'GET',
            url: event.params.path,
            dataType: 'json',
            success: function (response) {
                that.bodyTarget.innerHTML = response.html
            },
            error: function (response) {
                console.log('filter failed')
            }
        })
    }

    save(event) {
        let path = event.target.closest('form').getAttribute('action')
        console.log(`Instance#save path: ${path}`)

        let that = this

        event.preventDefault()

        let data = new FormData(this.submitTarget)
        data.append("commit", event.target.value)

        Rails.ajax({
            type: "post",
            url: path,
            data: data,
            success: function (response) {
                that.selfTarget.outerHTML = response.html

            },
            error: function (response) {
                console.log('Submit: Failed.')
            }
        })

        // Redraw the table

    }

}
