// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "results" ]

  connect() {
    console.log('Connected: Print')
    this._buttons_url = this.data.get('buttons-url')
    this._generate_url = this.data.get('generate-url')
    this._refreshIntervalValue = 1000
    this.load()
  }

  generate (event) {
    let that = this

    Rails.ajax({
      type: "get",
      url: this._generate_url,
      success: function (response) {
        console.log(response.html)
        that.resultsTarget.innerHTML = response.html
        that.startRefreshing()
      },
      error: function (response) { console.log('Submit: Failed.')}
    })
  }

  load() {
    let that = this

    Rails.ajax({
      type: "get",
      url: this._buttons_url,
      success: function (response) {
        that.resultsTarget.innerHTML = response.html

        console.log(response.finished)

        if (response.finished === true) {
          that.stopRefreshing()
        }
      },
      error: function (response) { console.log('Submit: Failed.')}
    })
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this._refreshIntervalValue)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
