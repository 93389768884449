import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="terminal-panel"
export default class extends Controller {
    static targets = ["status"]

    connect() {

        return false

        setTimeout(
            function () {
                toggleOn();
            }, 500);

        setTimeout(
            function () {
                togglePOn();
            }, 500);

        setTimeout(
            function () {
                toggleEmOn();
            }, 500);

    }

    togglePOff() {
        this.statusTarget.addClass('btn-callback-priority');
        this.statusTarget.removeClass('btn-callback-priority-on');
        setTimeout(
            function () {
                togglePOn();
            }, 500);
    }

    togglePOn = function () {
        this.statusTarget.addClass('btn-callback-priority-on');
        this.statusTarget.removeClass('btn-callback-priority');

        setTimeout(
            function () {
                togglePOff();
            }, 500);
    }


    toggleOff = function () {
        this.statusTarget.addClass('btn-callback');
        this.statusTarget.removeClass('btn-callback-on');
        setTimeout(
            function () {
                toggleOn();
            }, 500);
    }

    toggleOn = function () {
        this.statusTarget.addClass('btn-callback-on');
        this.statusTarget.removeClass('btn-callback');

        setTimeout(
            function () {
                toggleOff();
            }, 500);
    }


// Below needs to move to terminal specific file

    toggleEmOff = function () {
        this.statusTarget.addClass('terminal-emergency-off');
        this.statusTarget.removeClass('terminal-emergency-on');
        setTimeout(
            function () {
                toggleEmOn();
            }, 500);
    }

    toggleEmOn = function () {
        this.statusTarget.addClass('terminal-emergency-on');
        this.statusTarget.removeClass('terminal-emergency-off');

        setTimeout(
            function () {
                toggleEmOff();
            }, 500);
    }


}
