import { Controller } from "@hotwired/stimulus"
// import consumer from "channels/consumer"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["notes", "allocations"]
    static values = { "start-tracking-url": String }

    connect() {
        // this._org_id = this.data.get('id')
    }

    initialize() {
        // this.subscription()
    }

    standDown(event) {
        let that = this
        let url = event.target.name
        console.log('#standDown: ' + url)

        Rails.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',

            success: function (response) {
                console.log(response.incident_note)

                that.allocationsTarget.outerHTML = response.html
                that.notesTarget.innerHTML       = response.incident_note + that.notesTarget.innerHTML

            },
            error: function (response) { console.log('Search: Failed.')}
        })

    }

    revertLocation(event) {
        let that = this
        let url  = event.target.name

        Rails.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',

            success: function (response) {
                console.log(response.html)
                that.notesTarget.innerHTML = response.html + that.notesTarget.innerHTML
                that.notesTarget.firstChild.classList.add('bg-info')
            },
            error: function (response) { console.log('Search: Failed.')}
        })

    }

    ///////////////////////////////////
    // STUFF BELOW ARE EXAMPLES
    ///////////////////////////////////

    startTracking(event) {
        let that = this

        console.log('Start: ' + this.startTrackingUrlValue)

        Rails.ajax({
            type: 'GET',
            url: this.startTrackingUrlValue,
            dataType: 'json',

            success: function (response) {
                that.trackingTarget.outerHTML = response.html
            },
            error: function (response) { console.log('Search: Failed.')}
        })

    }

    stopTracking(event) {
        let that = this

        console.log('Stop: ' + event.target.name)

        Rails.ajax({
            type: 'GET',
            url: event.target.name,
            dataType: 'json',

            success: function (response) {
                that.trackingTarget.outerHTML = response.html
            },
            error: function (response) { console.log('Search: Failed.')}
        })
    }


    ///////////////////////////

    terminal_details(event) {

        this.url = event.target.dataset.terminalsUrl

        console.log('Show Terminal Details ' + this.url)
        console.log('Org ID:' + this._org_id)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: that.url + '?organisation_id=' + that._org_id,
            dataType: 'json',

            success: function (response) { console.log('Terminal: Got details') },
            error: function (response) { console.log('Search: Failed.')}
        })
    }


}