import { Controller } from "@hotwired/stimulus"
// import consumer from "channels/consumer"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["csnSummary", "activate"]

    connect() {
        console.log("SimCard#connect")

    }



    csnSwitch(event) {
        console.log("SimCard#csnOn")
        console.log(`URL: ${event.target.parentNode.name}`)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: event.target.parentNode.name,
            dataType: 'json',

            success: function (response) {
                event.target.parentNode.parentNode.parentNode.outerHTML = response.html
                that.csnSummaryTarget.innerHTML = response.csn_status

                if (response.subscription_filled) {
                    that.activateTargets.forEach((element, index) => {
                        element.hidden = true
                    })
                } else {
                    that.activateTargets.forEach((element, index) => {
                        element.hidden = false
                    })

                }

            },
            error: function (response) { console.log('Search: Failed.')}
        })

    }
}