import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["toast"]

    connect() {
        this.organisation = this.data.get('organisation')
    }

    initialize() {
        this.subscription()
    }

    terminal_details(event) {

        this.url = event.target.dataset.toasterUrl

        console.log('Show Terminal Details ' + this.url)
        console.log('Org ID:' + this.organisation)

        let that = this

        Rails.ajax({
            type: 'GET',
            url: that.url + '?organisation_id=' + that.organisation,
            dataType: 'json',

            success: function (response) { console.log('Terminal: Got details') },
            error: function (response) { console.log('Search: Failed.')}
        })
    }


    subscription() {
        if (this._subscription == undefined) {
            let _this = this

            console.log('ID: ' + _this.data.get("id"))

            this._subscription = consumer.subscriptions.create({ channel: "SimCardChannel", id: _this.data.get("id") }, {
                connected() {
                    console.log('Connected: SimCard')
                },
                disconnected() {
                    console.log('Not Connected')
                },
                received(data) {

                    console.log('We got something')
                    console.log(data.sim_card)
                    console.log('sim-card-' + data.sim_card_id)

                    let sim_card = document.getElementById('sim-card-' + data.sim_card_id)

                },
            })
        }
        return this._subscription
    }

}